export default {
    props: {
        prendas: Array,
        aforos: Object,
        slug: {type: String, default: 'empeno'}
    },
    data() {
        return {
            step: 1,
            type: [],
            category: null,
            garment: {},
            isMobile: window.innerWidth < 801,
            haveLoan: false,
            titles: [
                '¿Qué tipo de prenda vas a empeñar?',
                '',
                '',
                'Detalles de la prenda',
            ],
            weight_units: {
                'gramos': 'g',
                'onzas': 'oz'
            }
        }
    },
    provide() {
        return {
            weight_units: this.weight_units
        }
    },
    mounted() {
        window.onresize = () => {
            this.isMobile = window.innerWidth < 801
        }
    },
    methods: {
        setStep(step) {
            scroll(0, 0)
            if (step <= 0) {
                location.href = `/seccion/${this.slug}`
                this.step = 1
                return
            }
            this.step = step
        },
        setGarments(payload) {
            let garments = this.prendas.filter(prenda => prenda.tipo.value === payload.tipo.value && prenda.categoria.value === payload.categoria.value)[0]
            this.type = garments
            this.titles[this.step] = this.type.titulo_paso_2.value
            this.category = garments.categoria.value
            this.garment = {}
            this.garment.categoria = garments.categoria.value
            this.setStep(this.step + 1)
        },
        setGarment(itemIndex) {
            let prenda = this.type.prendas.value[itemIndex]
            this.garment.prenda = prenda.titulo === 'Otro' ? 'Joya' : this.type.prendas.value[itemIndex].titulo
            this.garment.unidades = prenda?.unidades
            if (prenda?.valor) {
                this.garment.valor = prenda.valor
                this.garment.cali_peso = prenda.peso
            }
            this.titles[this.step] = this.sectionThreeTitle()
            this.setStep(this.step + 1)
        },
        setGarmentDetails(details) {
            this.garment.kilataje = this.getKilataje(details.kilataje)
            this.garment.peso = parseFloat(details.gramos)
            this.garment.aforo = this.getAforo()
            this.garment.categoria = this.category
            this.setStep(this.step + 1)
        },
        getAforo() {
            let key = this.slug !== 'empeno' ? this.slug : this.category
            return this.aforos[key]
        },
        getKilataje(kilataje) {
            let karatType = {
                'oro': 'K',
                'plata': 'L',
            }
            return `${kilataje}${karatType[this.category]}`
        },
        sendGarment(loan) {
            this.garment.prestamo = loan.loan
            this.garment.loanInformation = loan.title
            this.garment.tipo = this.type.titulo.value
            this.$emit('getValuatedGarment', this.garment)
            this.haveLoan = true
        },
        async createLog(cta, reset = true) {
            if (this.garment?.prestamo && isNaN(this.garment.prestamo)) return
            this.garment.cta = cta
            this.garment.flujo = this.slug
            let log = null
            await this.$axios.post('/valuador/save_valuated_garments', {valuatedGarments: [this.garment]})
                .then((response) => {
                    log = response.data[0]
                    let last_quotation = {
                        src: window.location.href,
                        quotation_id: log._id,
                        date: new Date().toISOString()
                    }
                    this.saveQuotationCookie(last_quotation)
                })
                .catch((error) => {
                    console.error(error)
                })
            if (reset) this.setStep(1)
            return log['_id']['$oid']
        },
        garmentInformation() {
            let peso = this.garment?.cali_peso ? this.garment.cali_peso : this.garment.peso
            let type = 'g'
            if (this.garment?.unidades) type = this.weight_units[this.garment.unidades] ? this.weight_units[this.garment.unidades] : 'g'
            let regex = /(\d+(?:\.\d+)?)(\D+)/
            let kilataje = regex.exec(this.garment.kilataje)
            return `<b>${this.garment.prenda === 'Otro' ? 'Joya' : this.garment.prenda} de ${this.garment.categoria}</b> de <b>${kilataje[1]}${kilataje[2]}</b> de <b>${peso} ${type}</b>`
        },
        sectionThreeTitle() {
            if (!this.type?.tipo) return ''
            let title = this.type?.tipo?.value === 'joya' ? `Detalles de ${this.garment.prenda === 'Aretes' ? 'tus' : 'tu'} ${this.garment.prenda} de ${this.category}` :
                `Detalles de tu ${this.type['tipo']['value']} de ${this.category}`

            return title
        },
        saveQuotationCookie(quotation) {
            quotation = btoa(JSON.stringify(quotation))
            document.cookie = `last_quotation=${quotation}; path=/`
        }
    },
    watch: {
        step(newValue) {
            this.haveLoan = false
        },
    }
}